import styled from '@emotion/styled'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import useResizeObserver from 'src/hooks/useResizeObserver'
import { analysisResponseAtom } from 'src/views/AIModelGenerator/store/response/atoms'
import { selectModelState } from 'src/views/AIModelGenerator/store/userOption/atom'
import { colorChips } from '../../components/Chart/colors'
import InfoCircle from '../../components/Icon/InfoCircle'
import { SubTitle } from './FeatureAnalysis'
import FeatureImportance from './FeatureImportance'

const FeatureAnalysisCompact = () => {
  const { t } = useTranslation()
  const contentRef = useRef(null)
  const [isShowReadMore, setIsShowReadMore] = useState(false)

  const [chartData, setChartData] = useState({ labels: [], values: [] })
  const analysisResponse = useRecoilValue(analysisResponseAtom)
  const modelIdx = useRecoilValue(selectModelState)

  const observeCallback = (entries: any) => {
    for (const entry of entries) {
      if (entry.target.scrollHeight > Math.round(entry.contentRect.height)) {
        setIsShowReadMore(true)
      } else {
        setIsShowReadMore(false)
      }
    }
  }
  useResizeObserver({ callback: observeCallback, element: contentRef })

  useEffect(() => {
    setChartData(analysisResponse[modelIdx]['feature_data'])
  }, [modelIdx])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    contentRef.current.classList.add('show')
    setIsShowReadMore(false)
  }

  return (
    <>
      {/* <ModelPerformance />
      {analysisResponse[modelIdx].classes && <FeatureClassPerformance />} */}
      <div className="w-full rounded-[18px] bg-[#ffffff] border-[1px] border-[#d5dcef] p-4 shadow-[0px_0px_20px_#0000001a] px-[30px] py-[20px]">
        <SubTitle>
          {t('Feature Importance')}
          <InfoCircle
            content={t('The higher the feature importance, the greater its impact on the prediction model.')}
          />
        </SubTitle>
        <FeatureImportance data={chartData} colors={colorChips} />
      </div>
    </>
  )
}

export default FeatureAnalysisCompact

export const AIbutton = styled.button`
  display: block;
  float: left;
  background-color: #31d600;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  margin-right: 10px;
`

export const AITextContainer = styled.div`
  font-family: 'Helvetica Neue';
  width: 80%;
  display: block;
  float: left;
  color: #002d65;
  font-size: 12px;
  margin-bottom: 5px;
`
