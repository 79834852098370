import icon_01_inbox from 'src/assets/img/icons/main/service_01.png'
import icon_03_inbox from 'src/assets/img/icons/main/service_03.png'
import icon_04_inbox from 'src/assets/img/icons/main/service_04.png'

import { useTranslation } from 'react-i18next'
import icon_01_b from 'src/assets/img/icons/main/icon_01_b.png'
import icon_03_b from 'src/assets/img/icons/main/icon_03_b.png'
import icon_04_b from 'src/assets/img/icons/main/icon_04_b.png'

export const useMenuList = () => {
  const { t } = useTranslation()
  const isIneeji =
    localStorage.getItem('companyId') === process.env.REACT_APP_INEEJI_COMPANY_ID ||
    localStorage.getItem('userId')?.includes('ineeji.com')
      ? true
      : false

  const Menu: Array<any> = [
    {
      id: 1,
      title: t('Studio'),
      icon_src: icon_01_b,
      icon_inbox: icon_01_inbox,
      img_url: 'assets/img/icons/main/service_01.png',
      img_hover_url: 'assets/img/icons/main/icon_01_b.png',
      path: '/ai-model-generator',
      visible: true,
    },
    /*
    {
      id: 2,
      title: t('Explainable AI'),
      // title_KR: '설명가능 인공지능',
      icon_src: icon_02_b,
      icon_inbox: icon_02_inbox,
      img_url: 'assets/img/icons/main/service_02.png',
      img_hover_url: 'assets/img/icons/main/icon_02_b.png',
      path: '/xai-simulator',
    },
    */
    {
      id: 2,
      title: t('Index'),
      icon_src: icon_03_b,
      icon_inbox: icon_03_inbox,
      img_url: 'assets/img/icons/main/service_03.png',
      img_hover_url: 'assets/img/icons/main/icon_03_b.png',
      path: '/price-forecast',
      visible: true,
    },
    {
      id: 3,
      title: t('Index v2(beta)'),
      icon_src: icon_03_b,
      icon_inbox: icon_03_inbox,
      img_url: 'assets/img/icons/main/service_03.png',
      img_hover_url: 'assets/img/icons/main/icon_03_b.png',
      path: '/view-index',
      visible: isIneeji,
    },
    {
      id: 4,
      title: t('Prediction API'),
      icon_src: icon_04_b,
      icon_inbox: icon_04_inbox,
      img_url: 'assets/img/icons/main/service_04.png',
      img_hover_url: 'assets/img/icons/main/icon_04_b.png',
      path: '/api-service',
      visible: isIneeji,
    },
  ]
  return Menu
}

export default useMenuList
