import AIModelGenerator from 'src/views/AIModelGenerator'
import ModelGeneratorResult from 'src/views/AIModelGenerator/ModelGeneratorResult'
import ApiService from 'src/views/ApiService'
import { default as HRCView } from 'src/views/HRCView/HRCView'
import MainContents from 'src/views/Main/Main'
import MyPage from 'src/views/MyPage'
import PriceForecasting from 'src/views/PriceForecasting/OilPrice'
import ViewIndex from 'src/views/ViewIndex/index'
import XAIsimulator from 'src/views/XAI-simulator'

const routes: RoutesType[] = [
  {
    name: 'Main',
    layout: '/admin',
    path: '/main',
    icon: '',
    component: MainContents,
    needWhiteBackground: false,
  },
  {
    // name: 'AI Model Generator',
    name: 'Studio',
    layout: '/admin',
    path: '/ai-model-generator',
    icon: '',
    component: AIModelGenerator,
    visible: true,
    needWhiteBackground: true,
  },
  {
    name: 'View Model Results',
    layout: '/admin',
    path: '/view-model-results/:id',
    icon: '',
    component: ModelGeneratorResult,
    visible: false,
    needWhiteBackground: true,
  },
  {
    name: 'Explainable AI',
    layout: '/admin',
    path: '/xai-simulator',
    icon: '',
    component: XAIsimulator,
    visible: false,
    needWhiteBackground: true,
  },
  {
    name: 'View XAI Results',
    layout: '/admin',
    path: '/view-xai-results/:id',
    icon: '',
    component: XAIsimulator,
    visible: false,
    needWhiteBackground: true,
  },
  {
    name: 'Index',
    layout: '/admin',
    path: '/price-forecast',
    icon: '',
    component: PriceForecasting,
    visible: true,
    needWhiteBackground: true,
  },
  {
    name: 'My Profile',
    layout: '/admin',
    path: '/mypage',
    icon: '',
    component: MyPage,
    visible: false,
    needWhiteBackground: false,
  },
  {
    name: 'Index v2(beta)',
    layout: '/admin',
    path: '/view-index',
    icon: '',
    component: ViewIndex,
    visible:
      localStorage.getItem('companyId') === process.env.REACT_APP_INEEJI_COMPANY_ID ||
      localStorage.getItem('userId')?.includes('ineeji.com')
        ? true
        : false,
    needWhiteBackground: true,
  },
  {
    name: 'HRC Prediction View',
    layout: '/admin',
    path: '/hrc-view',
    icon: '',
    component: HRCView,
    visible: false,
    needWhiteBackground: true,
  },
  {
    name: 'Prediction API',
    layout: '/admin',
    path: '/api-service',
    icon: '',
    component: ApiService,
    visible:
      localStorage.getItem('companyId') === process.env.REACT_APP_INEEJI_COMPANY_ID ||
      localStorage.getItem('userId')?.includes('ineeji.com')
        ? true
        : false,
    needWhiteBackground: true,
  },
  // {
  //   name: 'Configuration',
  //   layout: '/admin',
  //   path: '/configuration',
  //   icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
  //   component: Configuration,
  // },
]

export default routes
