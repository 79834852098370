import { DownOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
import { ISymbol } from 'src/apis/type/IndexResponse'
import { graphDataState, horizonState, symbolState } from '../stores/atom'

const SymbolDropdown = () => {
  const [symbols, setSymbols] = useRecoilState(symbolState) //전체 리스트
  const setHorizon = useSetRecoilState(horizonState)
  const resetGraphData = useResetRecoilState(graphDataState)

  const [category, setCategory] = useState<string>('All')
  const [topItems, setTopItems] = useState<MenuProps['items']>([])
  const [subItems, setSubItems] = useState<MenuProps['items']>([])

  useEffect(() => {
    if (symbols.symbolList.length > 0) {
      setCategory(symbols.symbolList[0].category)
      setTopItems(generateItems([...new Set(symbols.symbolList.map((symbol) => symbol.category))]))
    }
  }, [symbols.symbolList])

  useEffect(() => {
    const filteredSubItems = symbols.symbolList.filter((symbol) => symbol.category === category)

    //하위 드롭다운 렌더
    setSubItems(generateItems(filteredSubItems))

    if (filteredSubItems.length > 0) {
      //0번째 심볼을 디폴트로 선택
      setSymbols({
        ...symbols,
        selectedSymbolData: filteredSubItems[0],
      })

      //해당 심볼의 horizon 업데이트
      setHorizon({
        horizonList: JSON.parse(filteredSubItems[0].horizons),
        selectedHorizon: JSON.parse(filteredSubItems[0].horizons)[0],
      })
    }
  }, [category])

  type CategoryOrSymbol = string | ISymbol

  function generateItems(arr: Array<CategoryOrSymbol>) {
    return arr.map((item) => {
      if (typeof item === 'string') {
        // categories 배열 처리
        return {
          label: item,
          key: item,
        }
      } else {
        // symbols 배열 처리
        return {
          label: item.symbol_id,
          key: item.symbol_id,
        }
      }
    })
  }

  const onClick: MenuProps['onClick'] = ({ key }) => {
    //prediction, raw data 초기화
    resetGraphData()
    //symbol 업데이트
    setSymbols({
      ...symbols,
      selectedSymbolData: symbols.symbolList.filter((symbol) => symbol.symbol_id === key)[0],
    })

    const symbol = symbols.symbolList.find((symbol) => symbol.symbol_id === key)

    //horizon 업데이트
    setHorizon({
      horizonList: JSON.parse(symbol.horizons),
      selectedHorizon: JSON.parse(symbol.horizons)[0],
    })
  }

  const onTopMenuClick: MenuProps['onClick'] = ({ key }) => {
    setCategory(key)
  }

  return (
    <div>
      <Dropdown menu={{ items: topItems, onClick: onTopMenuClick }}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            {category}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
      <span className="mx-2">/</span>
      <Dropdown
        menu={{
          items: subItems,
          onClick,
        }}
      >
        <a className="text-blue">
          <Space>
            {symbols.selectedSymbolData?.symbol_id}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </div>
  )
}

export default SymbolDropdown
