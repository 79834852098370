import { useEffect, useState } from 'react'
import TagManager, { DataLayerArgs } from 'react-gtm-module'
import { useTranslation } from 'react-i18next'

const ApiService = () => {
  const { t } = useTranslation()
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true)

  const [selectedRow, setSelectedRow] = useState()

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }
  useEffect(() => {
    const args: DataLayerArgs = {
      dataLayer: {
        event: 'virtualPageView',
        pageUrl: '/api-service',
        pageTitle: 'REST Api Service',
        user_id: localStorage.getItem('userId'),
      },
    }
    TagManager.dataLayer(args)
  }, [])

  const handleSelect = (param: any) => {
    setSelectedRow(param)
  }

  return (
    <>
      <div className="flex h-screen rounded-t-[25px]">
        {/* <Layout></Layout> */}
        {/* Sidebar */}
        {/* <div
          className={` bg-gray-100 text-white transition-all duration-300 ${
            isSidebarOpen ? 'w-64 bg-gray-800' : 'w-14'
          }`}
        >
          <div className="p-4 flex justify-between items-center">
            {isSidebarOpen && <h1 className="text-lg font-bold">Model List</h1>}
            <button onClick={toggleSidebar} className="text-gray-300 font-bold hover:font-bold focus:outline-none">
              {isSidebarOpen ? '<' : '>'}
            </button>
          </div>
          <nav className={`${isSidebarOpen ? 'block' : 'hidden'} mt-4`}>
            <CompactModelList />
          </nav>
        </div> */}

        {/* Main Content */}
        <div className="flex-grow bg-gray-100 p-8">
          <h2 className="text-xl font-bold">Main Content</h2>
          <p className="mt-4">
            main content
            {/* <SavedModelList data={data} onSelect={handleSelect} />
            <ApiSyntax /> */}
          </p>
        </div>
      </div>
    </>
  )
}
export default ApiService
