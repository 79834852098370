import styled from '@emotion/styled'
import { ThemeProvider } from '@mui/material/styles'
import { App } from 'antd'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import ModelApi from 'src/apis/ModelApi'
import { useGetModelList } from 'src/hooks/queries/useGetModelList'
import { validationCheck } from 'src/utils/DateFunction'
import { loadingAtom } from 'src/views/AIModelGenerator/store/global/atom'
import { analysisResponseAtom } from 'src/views/AIModelGenerator/store/response/atoms'
import { v4 } from 'uuid'
import InfoCircle from './components/Icon/InfoCircle'
import { stepCountStore } from './store/global/atom'
import { selectedModelAtom } from './store/model/atom'
import { theme } from './theme/theme'
import ClassificationResult from './Visualization/Data/ClassificationResult'
import RegressionResult from './Visualization/Data/RegressionResult'

const ModelGeneratorResult = () => {
  const { t } = useTranslation()
  const { message } = App.useApp()

  const selectedModel = useRecoilValue(selectedModelAtom)
  const setSelectedModel = useSetRecoilState(selectedModelAtom)

  const setActiveStep = useSetRecoilState(stepCountStore)
  const setAnalysisResult = useSetRecoilState(analysisResponseAtom)
  const setLoading = useSetRecoilState(loadingAtom)
  const MAX_DATA_COUNT = 5000

  const analysisResponse = useRecoilValue(analysisResponseAtom)

  const { id } = useParams<{ id: string }>()
  const userid = localStorage.getItem('userId')

  const { data } = useGetModelList(userid)

  const downloadData = async (url: string) => {
    try {
      const result = await ModelApi.getJsonResult(url)

      // (24-08-29) 끝에서 5000개만 그리도록 수정
      function sliceResultObj(obj: any, num: number) {
        // 객체의 키들을 배열로 변환
        const keys = Object.keys(obj)
        // 마지막 5000개의 키 추출
        const last5000Keys = keys.slice(-num)

        // 마지막 10개의 키-값 쌍으로 새 객체 생성
        const last5000Obj = last5000Keys.reduce((result: any, key: string) => {
          result[key] = obj[key]
          return result
        }, {})

        return last5000Obj
      }

      function sliceResultArr(obj: { pred: Array<unknown>; truth: Array<unknown> }, num: number) {
        return { pred: obj['pred'].slice(-num), truth: obj['truth'].slice(-num) }
      }

      setAnalysisResult([
        {
          key: v4(),
          pred_data:
            result['prediction_data']['pred'].length > MAX_DATA_COUNT
              ? sliceResultArr(result['prediction_data'], MAX_DATA_COUNT)
              : result['prediction_data'],
          feature_data: result['feature_piechart_data'],
          input: result['selected_input'],
          error: result['metrics'],
          row_data:
            Object.keys(result['result_table']).length > MAX_DATA_COUNT
              ? sliceResultObj(result['result_table'], MAX_DATA_COUNT)
              : result['result_table'],
          metrics: result['metrics'],
          performance: result['peformance_table'],
          uuid: result['get_uuid'],
          classes: Boolean(result['isClassification']) ? result['classes'] : null,
        },
      ])
      setActiveStep(1)
      setLoading(false)
    } catch (error) {
      console.error(error)
      message.error(t('Sorry. This request has expired.'))
    }
  }

  // TODO: common block refactoring 필요. ModelLisTable 에서 copy.
  const { mutate: mutateTrainingResult } = useMutation(ModelApi.getTrainingResultUrl, {
    onSuccess: (result: any) => {
      // GCS에서 받아온 만료시간이 GMT으로 설정되어 있어 한국 시간대(GMT + 9)로 변경하여 확인함
      if (validationCheck(result.expiration, 9)) downloadData(result.signed_url)
      else message.error(t('Sorry. This request has expired.'))
    },
    onError: () => {
      message.error(t('The result is not available. Please contact the administrator.'))
    },
  })

  useEffect(() => {
    if (id && data) {
      const dataArray = Array.isArray(data) ? data : []
      const foundItem = dataArray.find((item) => item.id === id)

      if (foundItem) {
        setSelectedModel(foundItem)
      }
      mutateTrainingResult({ model_id: id, is_xai: 'false' })
    }
  }, [id, data])

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="flex flex-col bg-white rounded-t-[25px] p-4 overflow-y-auto">
          <Title className="w-full ml-8 my-3">
            <Trans i18nKey="Prediction Results of" values={{ target_var: selectedModel.target }}>
              Prediction Results of {selectedModel.target}
            </Trans>
            <InfoCircle content={t('Prediction Results of the Model')} />
          </Title>
          <div className="flex h-screen">
            {analysisResponse.length > 0 &&
              (selectedModel.is_classification ? <ClassificationResult /> : <RegressionResult />)}
          </div>
        </div>
      </ThemeProvider>
    </>
  )
}

export default ModelGeneratorResult

const Title = styled.span`
  width: 100%;
  display: block;
  float: left;
  font-size: 30px;
  color: #002d65;
  font-weight: bolder;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
`
