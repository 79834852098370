import { UndoOutlined } from '@ant-design/icons'
import { Badge, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { styled } from 'styled-components'
// import { colorChips } from 'views/AIModelGenerator/components/Chart/colors'
import { colorChips } from 'src/views/AIModelGenerator/components/Chart/colors'
import { activeVariables, xaiResultStore } from '../store/analyze/atom'

const VariableSelector = () => {
  const { t } = useTranslation()
  const xaiResult = useRecoilValue(xaiResultStore)
  const [activeVars, setActiveVars] = useRecoilState(activeVariables)

  const handleClick = (value: number) => {
    setActiveVars((prev: any) => ({ ...prev, [value]: !prev[value] }))
  }

  const handleClearFilter = (e: any) => {
    setActiveVars((prevState: any) => {
      const updatedVars = { ...prevState }

      for (const [key, value] of Object.entries(updatedVars)) {
        updatedVars[key] = true
      }
      return updatedVars
    })
  }

  return (
    <>
      <div className="flex justify-end">
        <Button type="text" icon={<UndoOutlined />} onClick={handleClearFilter}>
          {t('Clear')}
        </Button>
      </div>
      <div className="bg-[#F5F8FF] rounded-[10px] border-[1px] border-[#D5DCEF]">
        <div className="w-6/7 p-3">
          {xaiResult.feature_list.map((value: number, index) => (
            <DynamicBadgeButton
              className="p-2 rounded-full m-1 min-w-[70px] font-['Helvetica Neue'] border-[#D5DCEF] text-xs"
              key={index}
              toggle={activeVars[value]}
              color={colorChips[index]}
              onClick={() => handleClick(value)}
            >
              <Badge
                className={`${activeVars[value] ? 'border-white' : `border-[${colorChips[index]}]`} m-1 text-sm`}
                color={activeVars[value] ? 'white' : colorChips[index]}
              />
              {value}
            </DynamicBadgeButton>
          ))}
        </div>
      </div>
    </>
  )
}

export default VariableSelector

const DynamicBadgeButton = styled.button<{ color: string; toggle: boolean }>`
  background-color: ${(props: any) => (props.toggle ? props.color : 'white')};
  color: ${(props: any) => (props.toggle ? '#FFFFFF' : '#174274')};
`
