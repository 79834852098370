import packageImg from 'src/assets/img/ineeji/package@2x.png'

const Banner = () => {
  return (
    <div className="flex w-full h-[110px] border border-[#d7dae0] rounded-xl">
      <div className="w-[30%] h-[100%]">
        <img className="w-[100%] m-auto p-[10px_15px]" src={packageImg} />
      </div>
      <div className="w-[70%] h-[100%] p-3">
        <p className="text-[#002d65] font-bold ">INEEJI’s Cloud AI, EEJI</p>
        <p className="text-[#002d65] text-[10px] leading-[12px]">
          is a time series-based prediction solution for energy saving, helping companies optimize industrial processes
          to improve productivity, reduce production energy costs, and enhance quality.
        </p>
      </div>
    </div>
  )
}

export default Banner
