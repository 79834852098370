import React from 'react'

const PanelWrapper = ({ panel1, panel2 }: { panel1: React.ReactNode; panel2: React.ReactNode }) => {
  return (
    <div className="flex rounded-[25px] h-screen">
      <div className="bg-[#FFFFFF] w-[65%] p-5 rounded-tl-[25px]">{panel1}</div>
      <div className="bg-[#F3F7FE] w-[35%] p-5 rounded-tr-[25px]">{panel2}</div>
    </div>
  )
}

export default PanelWrapper
