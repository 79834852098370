import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import IndexApi from 'src/apis/IndexApi'
import { IFeatureImportance } from 'src/apis/type/IndexResponse'
import { translatePeriodToKorean } from 'src/utils/TextTranslator'
import { horizonState, symbolState } from 'src/views/ViewIndex/stores/atom'
import { ComponentTitle } from '../CommonComponents'
import DescriptionCollapsePanel from '../DescriptionCollapsePanel'

const GlobalFeatureImportance = () => {
  const symbols = useRecoilValue(symbolState)
  const horizon = useRecoilValue(horizonState)

  const [featureImportance, setFeatureImportance] = useState([])
  const [series, setSeries] = useState([])
  const [selectedFeature, setSelectedFeature] = useState<Array<string> | null>(null)

  const { data: globalExplanation } = useQuery(
    ['globalExplanation', symbols.selectedSymbolData?.symbol_id, horizon.selectedHorizon],
    () => IndexApi.getGlobalExplanation(symbols.selectedSymbolData?.symbol_id, horizon.selectedHorizon),
    {
      enabled: !!symbols.selectedSymbolData?.symbol_id && !!horizon.selectedHorizon,
      refetchOnWindowFocus: false,
    }
  )

  useEffect(() => {
    if (globalExplanation) {
      setFeatureImportance(globalExplanation?.feature_importance)
      setSeries(
        globalExplanation?.feature_importance?.map((el: IFeatureImportance) => ({
          name: el.feature_name,
          data: [(el.importance * 100).toFixed(1)],
        }))
      )
      setSelectedFeature(null)
    }
  }, [globalExplanation])

  const options = {
    chart: {
      width: 380,
      type: 'donut' as const,
      events: {
        dataPointSelection: (event: any, chartContext: any, config: any) => {
          // 클릭된 데이터 포인트 정보
          const { dataPointIndex } = config
          setSelectedFeature([series[dataPointIndex].name])
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      show: false,
      position: 'bottom' as const,
      offsetY: 0,
      height: 230,
    },
    labels: series.map((s) => s.name), // Series 이름을 표시
  }

  return (
    <>
      <div className="mb-2">
        <ComponentTitle title="Feature Importance" />
        {featureImportance?.length > 0 && (
          <>
            <ReactApexChart options={options} series={series.map((s) => Number(s.data[0]))} type="donut" height={180} />
            <div className="my-2">
              {`${horizon.selectedHorizon}${translatePeriodToKorean(symbols.selectedSymbolData.period)} `}예측에서 가장
              영향력이 큰 변수는 <strong>{featureImportance[0]?.feature_name} </strong>입니다.
            </div>
            {selectedFeature && <DescriptionCollapsePanel selectedFeatures={selectedFeature} />}
          </>
        )}
      </div>
    </>
  )
}

export default GlobalFeatureImportance
