import { useHistory } from 'react-router-dom'
import ineeji from 'src/assets/img/ineeji/ineeji.svg'

export default function Logo(props: any) {
  const history = useHistory()

  const handleLogoClick = () => {
    history.replace('/admin/main')
  }
  return (
    <button {...props} className="focus:outline-none">
      <img src={ineeji} onClick={handleLogoClick} alt="Logo" />
    </button>
  )
}
