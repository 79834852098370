import { Modal } from 'antd'
import { useRecoilState } from 'recoil'
import logo_xs from 'src/assets/img/ineeji/logo_xs.svg'
import { modalState } from 'src/stores/modal'
import DataImportModal from 'src/views/AIModelGenerator/components/Modal/DataImportModal'
import ModelSaveModal from 'src/views/AIModelGenerator/components/Modal/ModelSaveModal'
import SendFeedback from 'src/views/Main/SendFeedback'
import SavedModelImport from 'src/views/XAI-simulator/components/SavedModelImport'
import UserModelImport from 'src/views/XAI-simulator/components/UserModelImport'

export const MODAL_TYPES = {
  UserModelImport: 'UserModelImport',
  SavedModelImport: 'SavedModelImport',
  DataImport: 'DataImport',
  SendFeedback: 'SendFeedback',
  ModelSave: 'SaveModel',
}

const ModalComponents: any = {
  [MODAL_TYPES.DataImport]: DataImportModal,
  [MODAL_TYPES.UserModelImport]: UserModelImport,
  [MODAL_TYPES.SavedModelImport]: SavedModelImport,
  [MODAL_TYPES.SendFeedback]: SendFeedback,
  [MODAL_TYPES.ModelSave]: ModelSaveModal,
}

const GlobalModal = () => {
  const [modal, setModal] = useRecoilState(modalState)
  const { modalType, modalProps, modalTitle } = modal || {}

  const renderComponent = () => {
    if (!modalType) {
      return null
    }
    const ModalComponent = ModalComponents[modalType]

    return (
      <Modal
        className="rounded-corners"
        width="420px"
        open={true}
        onCancel={() => setModal(null)}
        title={
          <>
            <img style={{ margin: '10px 0 5px 0' }} src={logo_xs} />
            <p style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: 30, color: '#002d65' }}>{modalTitle}</p>
          </>
        }
        footer={null}
      >
        {ModalComponent && <ModalComponent {...modalProps} />}
      </Modal>
    )
  }

  return <>{renderComponent()}</>
}

export default GlobalModal
