import ModelPerformance from 'src/views/XAI-simulator/ModelPerformance'
import CustomTable from '../../components/Table/CustomTable'
import FeatureAnalysisCompact from '../Features/FeatureAnalysisCompact'
import FeatureClassPerformance from '../Features/FeatureClassPerformance'

const ClassificationResult = () => {
  return (
    <>
      <div className="flex w-full justify-between gap-4">
        <div className="flex-[5] ml-8 overflow-scroll">
          <CustomTable />
        </div>
        <div className="flex-[2] mr-4 -mt-[30px]">
          <div className="flex flex-col gap-2">
            <ModelPerformance />
            <FeatureClassPerformance />
            <FeatureAnalysisCompact />
          </div>
        </div>
      </div>
    </>
  )
}

export default ClassificationResult
