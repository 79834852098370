import { useQuery } from 'react-query'
import { TResponseType } from 'src/apis/type/commonResponse'
import { ICompanyList } from 'src/apis/type/User'
import UserApi from 'src/apis/UserApi'

const useGetCompanies = () => {
  const { status, data } = useQuery<TResponseType<ICompanyList>, unknown>(['companyList'], () =>
    UserApi.getCompanyList()
  )
  return { status, data }
}

export default useGetCompanies
