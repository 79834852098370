import { Tabs } from 'antd'
import { TabsProps } from 'antd/es/tabs'
import { useState } from 'react'

import MetricsTable from 'src/views/ViewIndex/PerformancePanel/MetricsTable'
import GlobalFeatureImportance from './FeaturePanel/GlobalFeatureImportance'
import LocalAttrTable from './FeaturePanel/LocalAttrTable'

export const ExplanationPanel = () => {
  const [activeKey, setActiveKey] = useState('1')

  const onChange = (key: string) => {
    setActiveKey(key)
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Feature Importance',
      children: <GlobalFeatureImportance />,
    },
    {
      key: '2',
      label: 'Local Attribution',
      children: <LocalAttrTable />,
    },
    {
      key: '3',
      label: 'Performance',
      children: <MetricsTable />,
    },
  ]

  return (
    <>
      <div className="mx-2 h-[280px]">
        <Tabs items={items} onChange={onChange} size="small" />
      </div>
    </>
  )
}
