import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse, theme } from 'antd'
import React, { CSSProperties } from 'react'
import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import IndexApi from 'src/apis/IndexApi'
import { IFeatureDescription } from 'src/apis/type/IndexResponse'
import { horizonState, symbolState } from '../stores/atom'

const DescriptionCollapsePanel = ({ selectedFeatures }: { selectedFeatures: Array<string> }) => {
  const symbols = useRecoilValue(symbolState)
  const horizon = useRecoilValue(horizonState)

  const { token } = theme.useToken()

  const panelStyle: React.CSSProperties = {
    // marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  }

  const { data } = useQuery({
    queryKey: ['featureDescription', symbols.selectedSymbolData?.symbol_id, horizon.selectedHorizon],
    queryFn: () => IndexApi.getFeatureDescription(symbols.selectedSymbolData?.symbol_id, horizon.selectedHorizon),
    enabled: !!symbols.selectedSymbolData?.symbol_id && !!horizon.selectedHorizon,
  })

  const generateItems = (panelStyle: CSSProperties, fArray: Array<string>) => {
    const descriptionData = data?.data?.filter((f: IFeatureDescription) => fArray.includes(f.feature_name))

    return descriptionData?.map((feature, i) => ({
      key: i.toString(),
      label: <p>{feature?.feature_name}</p>,
      children: (
        <>
          <div className="my-2">
            <div className="mb-2"></div>
            <div>{feature?.description}</div>
            <br />
            <div>{feature?.causal_relationship}</div>
            {/* <span> (단위 : {selectedFeature?.unit})</span> */}
            {/* <div>(출처 : {selectedFeature?.source})</div> */}
          </div>
        </>
      ),
      style: panelStyle,
    }))
  }

  return (
    <Collapse
      accordion
      bordered={false}
      size="small"
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      style={{ background: token.colorBgContainer }}
      items={generateItems(panelStyle, selectedFeatures)}
    />
  )
}

export default DescriptionCollapsePanel
