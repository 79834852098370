// Chakra imports
import Header from 'src/components/navbar/Header'
// Layout components
import { Route } from 'react-router-dom'
import routes from 'src/routes'

// Custom Chakra theme
export default function Main() {
  document.documentElement.dir = 'ltr'

  const needWhiteBackground = () => {
    const needScreenWidthRoutes = routes
      .filter((value) => value.needWhiteBackground === true)
      .map((item) => item.layout + item.path)

    return Boolean(needScreenWidthRoutes.includes(window.location.pathname))
  }

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === '/admin') {
        return <Route path={route.layout + route.path} component={route.component} key={key} />
      } else {
        return null
      }
    })
  }

  return (
    <>
      <div className="h-screen flex flex-col">
        <Header routes={routes}></Header>
        {needWhiteBackground() ? (
          <div className="h-screen flex-grow bg-[#F5F8FF] rounded-t-[25px]">{getRoutes(routes)}</div>
        ) : (
          getRoutes(routes)
        )}
        {/* <div className="h-[100px] bottom-0 text-center">
          <Footer />
        </div> */}
      </div>
    </>
  )
}
