//key colors 15개
export const keyColors = [
  '#FF9936',
  '#FFE346',
  '#893CC1',
  '#8779ED',
  '#617EFF',
  '#45E516',
  '#F77070',
  '#FF3D50',
  '#52C2CC',
  '#FF3D50',
  '#63C1FA',
  '#1A73FF',
  '#617EFF',
  '#8779ED',
  '#893CC1',
]

//low chromatic key color 11개
export const lowChromaticColorChips = [
  '#1A4A68',
  '#316692',
  '#5EA9D1',
  '#60B8CC',
  '#BFE9E6',
  '#CAE8FF',
  '#F1E7DB',
  '#F9D6CC',
  '#F9C6CA',
  '#E0BFC6',
  '#C6B9C5',
]

//48개까지 확장한 colorsd
export const colorChips = [
  '#091A7A',
  '#102693',
  '#102693',
  '#254EDB',
  '#3366FF',
  '#6690FF',
  '#84A9FF',
  '#ADC8FF',
  '#D3CBFD',
  '#BAB0F9',
  '#A69AF4',
  '#8779ED',
  '#6458CB',
  '#473CAA',
  '#2E2689',
  '#1D1771',
  '#0C4675',
  '#14608D',
  '#1F85AF',
  '#2EAFD1',
  '#3FDCF4',
  '#95EB61',
  '#70CA46',
  '#4FA930',
  '#33881E',
  '#1F7012',
  '#7A2207',
  '#93330C',
  '#B74C13',
  '#DB681C',
  '#FF8927',
  '#FFAF5D',
  '#FFC67D',
  '#FFDDA8',
  '#FFBFB1',
  '#FF948A',
  '#FF6D6E',
  '#FF3D50',
  '#DB2C4D',
  '#B71E49',
  '#931343',
  '#7A0B3E',
]
