import styled from '@emotion/styled'
import { Button, Drawer, message } from 'antd'
import { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import XaiApi from 'src/apis/XaiApi'
import { colorChips as STACKED_BAR_CHART_COLORS, keyColors } from 'src/views/AIModelGenerator/components/Chart/colors'
import { MenuTitle } from 'src/views/AIModelGenerator/components/Input/Text'
import GlobalFeatureImportance from './components/GlobalFeatureImportance'
import PDP_Plot from './components/PDP_Plot'
import VariableSelector from './components/VariableSelector'
import { transformDataByRow } from './functions'
import { activeVariables, localAttrState, xaiPaginationStore, xaiResultStore } from './store/analyze/atom'
import AnalysisGrid, { DataRow } from './Visualization/AnalysisGrid'

const XaiAnalysisResult = () => {
  const { t } = useTranslation()
  const xaiPagination = useRecoilValue(xaiPaginationStore)
  const filteredData = useRecoilValue(localAttrState)
  const [xaiResult, setXaiResult] = useRecoilState(xaiResultStore)
  const setActiveVars = useSetRecoilState(activeVariables)
  const [open, setOpen] = useState(false)

  const { mutate: mutateXaiResult } = useMutation(XaiApi.getPaginatedXaiResult, {
    onSuccess: (result: any) => {
      if (Object.keys(result).length > 0)
        setXaiResult({
          ...xaiResult,
          sample_size: result['sample_size'],
          feature_length: result.feature_list.length,
          feature_list: result['feature_list'],
          predict_result: result['predict_result'],
          input_data: transformDataByRow(
            xaiPagination.limit,
            xaiPagination.offset,
            result['input_data'],
            xaiPagination.total
          ),
          xai_local: transformDataByRow(
            xaiPagination.limit,
            xaiPagination.offset,
            result['xai_local'],
            xaiPagination.total
          ),
          xai_global: result['xai_global'],
          xai_pdp: result['xai_pdp'],
          colors: result['feature_list'].length <= 15 ? keyColors : STACKED_BAR_CHART_COLORS,
        })
    },
    onError: (error: AxiosError) => {
      message.error(error.message)
    },
  })

  useEffect(() => {
    //각 입력변수별로 활성화 여부를 담는 배열 세팅
    const obj = xaiResult?.feature_list.reduce((accumulator, value) => {
      return { ...accumulator, [value]: true }
    }, {})
    setActiveVars(obj)
  }, [])

  useEffect(() => {
    //자식 컴포넌트에서 pagination 설정 바뀔 때마다 데이터 다시 가져옴
    mutateXaiResult({ model_id: xaiResult.model_id, offset: xaiPagination.offset, limit: xaiPagination.limit })
  }, [xaiPagination])

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <div className="w-full h-screen bg-white rounded-t-[25px] p-5">
        <MenuTitle className="mt-[20px] ml-[30px] w-[63%] h-[47px]">XAI</MenuTitle>
        <div className="flex flex-row">
          <div className="flex-[7] mr-2">
            <div className="mx-7">
              <Drawer title={t('Input Variable Filtering')} onClose={onClose} open={open}>
                <VariableSelector />
              </Drawer>
              <div>
                <Title>{t('Prediction Model Explanation Results')}</Title>
                <Button type="default" onClick={showDrawer} className="float-right  border-[#D5DCEF] ">
                  {t('Input Variable Filtering')}
                </Button>
                <AnalysisGrid
                  featureList={xaiResult?.feature_list}
                  localWeight={filteredData}
                  localValue={xaiResult?.input_data}
                  predResult={xaiResult?.predict_result.predict_result}
                />
              </div>
            </div>
          </div>
          <div className="flex-[3] mx-2">
            {xaiResult?.xai_pdp && <PDP_Plot pdpData={xaiResult.xai_pdp} target={xaiResult.target} />}
            {xaiResult?.xai_global ? (
              <GlobalFeatureImportance data={xaiResult?.xai_global} colors={xaiResult?.colors} />
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(XaiAnalysisResult)

interface Container {
  width?: any
  height?: any
  minHeight?: any
  position?: string
}

const Title = styled.span`
  color: #002d65;
  font-family: 'Helvetica Neue';
  font-size: 17px;
  margin: 5px 3px;
  font-weight: bold;
`

const VariableRow = styled(DataRow)`
  background-color: #f5f8ff;
  margin-bottom: 10px;
  border: 1px solid #d5dcef;
`

const Container = styled.div`
  width: 100%;
  height: 35vw;
  // margin-top: 2vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const RoundedBox = styled.div<Container>`
  // padding-right: 0.5vw;
  background-color: #fff;
  box-shadow: 0px 5px 10px #4338f733;
  border-radius: 20px;
  padding: 2vw;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : 'auto')};
  position: ${(props) => (props.position ? props.position : 'relative')};
  overflow: auto;

  overflow-y: scroll;
  overflow-x: scroll;
  overflow: -moz-scrollbars-horizontal;
  overflow: -moz-scrollbars-vertical;

  &::-webkit-scrollbar {
    background: #332bbf;
    border-radius: 30%; //width가 너무 작아서 안보임..
    width: 4px;
    height: 4px;
    display: flex;
    overflow: auto;
  }
  &::-webkit-scrollbar-thumb {
    background: #332bbf;
    border-radius: 10%;
  }

  &::-webkit-scrollbar-track {
    background: #d5dcef;
    border-radius: 10%;
  }
`

const ScrollContainer = styled.div`
  overflow-y: scroll;
  overflow-x: scroll;
  overflow: -moz-scrollbars-horizontal;
  overflow: -moz-scrollbars-vertical;

  &::-webkit-scrollbar {
    background: #332bbf;
    border-radius: 30%; //width가 너무 작아서 안보임..
    width: 4px;
    height: 4px;
    display: flex;
    overflow: auto;
  }
  &::-webkit-scrollbar-thumb {
    background: #332bbf;
    border-radius: 10%;
  }

  &::-webkit-scrollbar-track {
    background: #d5dcef;
    border-radius: 10%;
  }
`
