import { useTranslation } from 'react-i18next'
import { MenuTitle } from '../Input/Text'
import ModelListTable from './ModelListTable'

const ModelList = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col m-10">
      <div className="flex-1">
        <MenuTitle>{t('Model List')}</MenuTitle>
      </div>
      <div className="flex-1 mt-5 mr-5">
        <ModelListTable />
      </div>
    </div>
  )
}

export default ModelList
