import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Statistic, Table } from 'antd'
import { useEffect, useState } from 'react'
import { useQueries, useQuery } from 'react-query'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import IndexApi from 'src/apis/IndexApi'
import { IFeatureImpact } from 'src/apis/type/IndexResponse'
import { ComponentTitle } from 'src/views/ViewIndex/ExplanationPanel/CommonComponents'
import {
  FeatureImpactDataState,
  horizonState,
  RawDataState,
  selectedFilterState,
  symbolState,
} from 'src/views/ViewIndex/stores/atom'
import DescriptionCollapsePanel from '../DescriptionCollapsePanel'

interface DataType {
  key: React.Key
  feature_name: string
  impact: number
  input_value_delta: number
  input_value_delta_percentage: number
  date_input: string
}

const columns = [
  {
    title: 'Feature Name',
    dataIndex: 'feature_name',
    align: 'center' as const,
    sorter: (a: IFeatureImpact, b: IFeatureImpact) => a.feature_name.localeCompare(b.feature_name),
  },
  {
    title: 'Impact',
    dataIndex: 'impact',
    align: 'center' as const,
    sorter: (a: IFeatureImpact, b: IFeatureImpact) => a.impact - b.impact,
    render: (number: number) => (
      <Statistic
        value={number}
        valueStyle={{ color: number > 0 ? '#4337F6' : '#cf1322', fontSize: '12px' }}
        prefix={number > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
      />
    ),
  },
  {
    title: 'Input Value Delta',
    dataIndex: 'input_value_delta',
    align: 'center' as const,
    sorter: (a: IFeatureImpact, b: IFeatureImpact) => a.input_value_delta - b.input_value_delta,
    render: (number: number, record: DataType) =>
      `${number} (${(record.input_value_delta_percentage * 100).toFixed(2)}%)`,
  },
]

const LocalAttrTable = () => {
  const symbols = useRecoilValue(symbolState)
  const horizon = useRecoilValue(horizonState)
  const [selectedFilter, setSelectedFilter] = useRecoilState(selectedFilterState)
  // const [selectedFeatures, setSelectedFeatures] = useState<Array<string>>([])
  const setFeatureImpactData = useSetRecoilState(FeatureImpactDataState)
  const [data, setData] = useState([])
  const [summary, setSummary] = useState({ positive: 0, negative: 0, total: 0 })
  const setRawData = useSetRecoilState(RawDataState)

  const { data: featureData } = useQuery(
    ['localAttribution', symbols.selectedSymbolData?.symbol_id, selectedFilter.selectedDate],
    () =>
      IndexApi.getLocalAttributionByDate(
        symbols.selectedSymbolData?.symbol_id,
        horizon.selectedHorizon,
        selectedFilter.selectedDate,
        1, // 예측날짜 기준 조회
        1 // is_sorted 1인경우 positive, negative 별도로 넘어옴
      ),
    {
      enabled: !!symbols.selectedSymbolData?.symbol_id && !!horizon.selectedHorizon && !!selectedFilter.selectedDate,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setData([])
        if (data) {
          const positive = data.feature_impact.positive.map((item: IFeatureImpact) => ({
            ...item,
            key: item.feature_name,
            type: 'positive',
          }))

          const negative = data.feature_impact.negative.map((item: IFeatureImpact) => ({
            ...item,
            key: item.feature_name,
            type: 'negative',
          }))

          const mergedResult = [...positive, ...negative]
          setData(mergedResult)

          setFeatureImpactData({
            name: data.name,
            feature_impact: mergedResult,
            horizon: data.horizon,
            is_pred_date: data.is_pred_date,
            date_pred: data.date_pred,
            dt: data.dt,
            date: data.date,
            date_input: data.date_input,
          })

          setSummary({
            positive: Number(
              positive
                .map((item) => item.impact)
                .reduce((a, b) => a + b, 0)
                .toFixed(4)
            ),
            negative: Number(
              negative
                .map((item) => item.impact)
                .reduce((a, b) => a + b, 0)
                .toFixed(4)
            ),
            total: Number(
              mergedResult
                .map((item) => item.impact)
                .reduce((a, b) => a + b, 0)
                .toFixed(4)
            ),
          })
        }
      },
    }
  )

  const fetchRawData = async (symbolId: string, featureName: string) => {
    const response = await IndexApi.getRawData(symbolId, featureName)
    return response
  }

  const useMultipleFeatureData = () => {
    const selectedFeatures = selectedFilter.selectedFeatures

    //선택된 feature들에 대한 병렬 요청
    const queries = useQueries(
      selectedFeatures.map((featureName: string) => ({
        queryKey: ['feature', symbols.selectedSymbolData?.symbol_id, featureName],
        queryFn: () => fetchRawData(symbols.selectedSymbolData?.symbol_id, featureName),
        enabled: !!symbols.selectedSymbolData?.symbol_id && !!featureName,
        staleTime: 1000 * 60 * 10, //10분
      }))
    )

    return queries
  }

  const queries = useMultipleFeatureData()

  useEffect(() => {
    //요청이 완료되기 전까지 undefined로 값이 들어오므로 필터링
    const rawData = queries.map((query: any) => query.data?.data).filter((item) => item !== undefined && item !== null)
    const isLoaded = queries.every((query) => query.isFetched)

    if (isLoaded && rawData.length === selectedFilter.selectedFeatures.length) {
      const reformatData = (data: Array<{ [key: string]: Array<unknown> }>) => {
        return data.reduce((result, obj) => {
          for (const [key, values] of Object.entries(obj)) {
            //필요한 값들만 추출
            result[key] = values.map((item: Array<unknown>) => item[1])
          }
          return result
        }, {})
      }

      const output = reformatData(rawData)

      //TODO : 모두 같은 Key값을 가지고 있어 loop를 돌지 않고 하드코딩 함...
      setRawData({ data_key: ['date', 'value'], data: output })
    }
  }, [queries])

  const handleFeatureToggle = (record: DataType) => {
    const selectedFeature = record.feature_name

    setSelectedFilter((prev: any) =>
      prev.selectedFeatures.includes(selectedFeature)
        ? { ...prev, selectedFeatures: prev.selectedFeatures.filter((feature: string) => feature !== selectedFeature) }
        : { ...prev, selectedFeatures: [...prev.selectedFeatures, selectedFeature] }
    )
  }

  useEffect(() => {
    initializeData()
  }, [symbols.selectedSymbolData?.symbol_id])

  const initializeData = () => {
    setData([])
    setSummary({ positive: 0, negative: 0, total: 0 })
  }

  return (
    <div className="mb-2">
      <ComponentTitle title="Local Attribution" />
      <span className={`${featureData?.date_input ? 'text-[12px] text-gray-500' : 'hidden'} mx-3`}>
        (입력 구간 : {featureData?.date_input} - {featureData?.date})
      </span>
      <div className="text-right text-[12px]">( 단위 : {symbols.selectedSymbolData?.unit} )</div>
      <Table
        className="mt-2"
        columns={columns}
        dataSource={data}
        rowSelection={{ type: 'checkbox', selectedRowKeys: selectedFilter.selectedFeatures }}
        onRow={(record) => ({
          onClick: () => handleFeatureToggle(record),
        })}
        size="small"
        pagination={{ pageSize: 5, pageSizeOptions: [5], position: ['bottomCenter'], showSizeChanger: false }}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Positive</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <p className="text-[#4337F6]">{Number(summary.positive).toFixed(4)}</p>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0}>Negative</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <p className="text-[#D84247]">{Number(summary.negative).toFixed(4)}</p>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Prediction</Table.Summary.Cell>
                <Table.Summary.Cell index={1} rowSpan={2}>
                  {summary.total !== 0 && (
                    <>
                      <span className={`${summary.total > 0 ? 'text-[#4337F6]' : 'text-[#D84247]'}`}>
                        {Number(summary.total).toFixed(4)}
                      </span>
                    </>
                  )}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }}
      />
      {selectedFilter.selectedFeatures && (
        <DescriptionCollapsePanel selectedFeatures={selectedFilter.selectedFeatures} />
      )}
    </div>
  )
}

export default LocalAttrTable
