import { useHistory } from 'react-router-dom'
import logoutBtnImage from 'src/assets/img/icons/logout_off.svg'
import { useLogout } from 'src/hooks/useLogout'

const Logout = () => {
  const history = useHistory()
  const { handleLogout } = useLogout(() => {
    history.replace('/login')
  })

  return (
    <>
      <button onClick={handleLogout}>
        <img src={logoutBtnImage} />
      </button>
    </>
  )
}

export default Logout
