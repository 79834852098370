import { UndoOutlined } from '@ant-design/icons'
import { Button, Select, Table, TableProps } from 'antd'
import { createStyles } from 'antd-style'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { filteredResultState } from 'src/views/AIModelGenerator/store/response/atoms'

export interface IConstraint {
  data: string
  filterKey: string
  filterValue: number | string
}
export interface IStringKeyObject {
  [key: string]: string | number
}

interface DataType {
  key: string
  실제: string
  예측결과: string
  [key: string]: string | number
}

const defualtColumns: TableProps<unknown>['columns'] = [
  {
    title: 'Index',
    dataIndex: 'key',
    key: 'key',
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Truth',
    dataIndex: '실제',
    key: '실제',
    align: 'center',
    fixed: 'left',
  },
  {
    title: 'Predict',
    dataIndex: '예측결과',
    key: '예측결과',
    align: 'center',
    fixed: 'left',
  },
]

const useStyle = createStyles(({ css }) => {
  return {
    customTable: css`
      .ant-table {
        .ant-table-container {
          .ant-table-body,
          .ant-table-content {
            scrollbar-width: thin;
            scrollbar-color: #eaeaea transparent;
            scrollbar-gutter: stable;
          }
        }
      }
    `,
  }
})

const CustomTable = () => {
  const { styles } = useStyle()
  const rowData: any = useRecoilValue(filteredResultState('row_data'))[0]
  const classList: any = useRecoilValue(filteredResultState('classes'))[0]
  const columns: any = useRecoilValue(filteredResultState('input'))[0]

  const [tableColumns, setTableColumns] = useState<TableProps<DataType>['columns']>()

  const [originalData, setOriginalData] = useState([])
  const [data, setData] = useState([])
  const [options, setOptions] = useState([])

  const [constraints, setConstraints] = useState(Array<IConstraint>)
  const [selectValue, setSelectValue] = useState({ 실제: {}, 예측결과: {} })

  useEffect(() => {
    const columnsToAdd = columns.map((col: string, idx: number) => ({
      title: col,
      dataIndex: col,
      key: col,
      align: 'center',
    }))

    const newColumns = [...defualtColumns, ...columnsToAdd]
    setTableColumns(newColumns)
  }, [columns])

  useEffect(() => {
    const newArr: Array<any> = []

    Object.entries(rowData).map(([key, value]: any, idx: number) => {
      newArr.push({ key: idx.toString(), ...value })
    })

    //원본 저장(필터링 전)
    setOriginalData(newArr)

    //테이블 렌더링 용
    setData(newArr)

    const newOption: Array<any> = []
    classList?.map((item: any, index: number) => {
      newOption.push({ value: item, label: item })
    })

    setOptions(newOption)
  }, [])

  useEffect(() => {
    if (constraints?.length > 0) {
      let filteredData = originalData
      constraints.map((constraint: any, idx: number) => {
        if (idx === 0) {
          filteredData = originalData.filter((item: any) => item[constraint?.filterKey] === constraint?.filterValue)
        } else {
          filteredData = filteredData.filter((item: any) => item[constraint?.filterKey] === constraint?.filterValue)
        }
        setData(filteredData)
      })
    }
  }, [constraints])

  const handleChange = (selectedValue: any, filterKey: string) => {
    setSelectValue({ ...selectValue, [filterKey]: { value: selectedValue, label: selectedValue } })
    setConstraints([
      ...constraints.filter((item: any) => item.filterKey !== filterKey),
      { data: 'row_data', filterKey: filterKey, filterValue: selectedValue },
    ])
  }

  const handleClearFilter = () => {
    setConstraints([])
    setSelectValue({ 실제: { label: '', value: '' }, 예측결과: { label: '', value: '' } })
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-none">
        <div className="text-[#002d65] font-['Helvetica_Neue'] text-[17px] m-[5px_3px] font-bold">
          {t('Data Filtering')}
        </div>
        <div className="ml-1">
          <div className="mr-3 inline-block ">
            {t('Truth')} :{' '}
            <Select
              className="w-[120px]"
              placeholder="Select..."
              value={selectValue['실제'] || undefined}
              onChange={(e: any) => handleChange(e, '실제')}
              options={options}
            />
          </div>
          <div className="mr-3 inline-block">
            {t('Predict')} :{' '}
            <Select
              className="w-[120px]"
              placeholder="Select..."
              value={selectValue['예측결과'] || undefined}
              onChange={(e: any) => handleChange(e, '예측결과')}
              options={options}
            />
          </div>
          <Button type="text" icon={<UndoOutlined />} onClick={handleClearFilter}>
            {t('Clear Filter')}
          </Button>
        </div>
      </div>
      <div className="flex-grow w-full mt-2">
        <Table
          size="middle"
          className={styles.customTable}
          columns={tableColumns}
          scroll={{ x: 'max-content' }}
          dataSource={data}
          pagination={{ pageSize: 10, pageSizeOptions: [10], position: ['bottomCenter'], showSizeChanger: false }}
          bordered
        />
      </div>
    </div>
  )
}

export default CustomTable
